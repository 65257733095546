.link{
    text-decoration: none;
    padding: 0 12px 0 0;
    color: #3e3e3e;
}
.link:hover{
    color: #000000;
}
.navbar a{
    text-decoration: none;
    color: #3e3e3e;
}
.navbar a:hover {
    color: #000000;
}